<template>
  <div class="page">
    <div v-if="loading">
      <div class="head"></div>
      <van-skeleton :row="6" />
    </div>
    <div v-else>
      <div class="head">
        <div class="title">
          <p>
            <span class="b">当前状态：</span>
            <span>{{niming ? '匿名用户' : user_info['role']}}</span>
          </p>
        </div>
        <div class="about">
          <div class="l1"><p>{{!niming && !user_info['phone']['has'] ? '暂未绑定手机号': '已绑定手机号：'+ user_info['phone']['content']}}</p> </div>
        </div>
      </div>
      <div>
        <div class="med">
          <GreenTips word='通过手机号找回' />
          <p>如果账号已关联手机号，请下载花样起名app，使用手机号登录</p>
        </div>
        <div class="med">
          <GreenTips word='通过支付信息找回' />
          <p>您也通过微信支付记录，找到记录中的商户订单号，通过【商户订单号】找回</p>
          <div class="input_div">
            <input v-model="order_id" placeholder-class="placeholder_long" type="text" placeholder="输入订单号" />
            <span @click="find_order_by_order">确 定</span>
          </div>
          <div v-if="has_order">
            <div class="other_user_div">
              <ul>
                <li class="al2">用户：{{other_user_info['nickname']}}</li>
                <li class="al2">创建时间：{{other_user_info['create_time']}}</li>
              </ul>
               <ul>
                <li class="al2">手机号：{{other_user_info['phone']['has'] ? other_user_info['phone']['content'] : '未绑定'}}</li>
                <li class="al2">收藏名字：{{other_user_info['mark_name_nums']}}个</li>
              </ul>
              <ul v-if="other_user_info['vip_info']['is_vip']">
                <li class="al2">是否会员：{{other_user_info['vip_info']['is_vip'] ? '是' : '否'}}</li>
                <li class="al2">剩余会员天数：{{other_user_info['vip_info']['have_days']}}</li>
              </ul>
              <ul v-if="other_user_info['desc']">
                <li  class="al1">说明：{{other_user_info['desc']}}</li>
              </ul>
            </div>
            <button v-if="other_user_info['can_move']" class="black in_body"  @click="move_user_by_order" >迁移到本账号</button>
          </div>
        </div>
        <div class="med">
          <GreenTips word='通过客服找回' />
          <p>如果上两种方式都没有成功，还可以联系客服找回。此方式有一定延迟，耽搁的会员时间我们会相应补回</p>
          <button class="black in_body"  data-value="contact" @click="pull_up" >联系客服，找回订单</button>
        </div>
        <ul id="last_right_b" class="last_right">
          <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
        </ul>
      </div>
      <van-popup v-model:show="show.contact" round position="bottom" :style="{ height: '72%' }" @close="on_close">
        <Contact></Contact>
      </van-popup>
      <van-popup v-model:show="show.zc" round position="bottom" :style="{ height: '72%' }" @close="on_close">
        <Login></Login>
      </van-popup>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import GreenTips from '@/components/items/GreenTips'
import axios from 'axios'
import Contact from '@/components/other/Contact'

export default {
  name: 'order_find',
  store,
  components: {
    GreenTips, Contact
  },
  data () {
    return {
      base_img: store.state.base_img,
      niming: store.state.niming,
      is_ios: Utils.is_ios(),
      order_id: '',
      loading: true,
      has_order: false,
      user_info: {},
      other_user_info: {},
      show: { contact: false }
    }
  },
  mounted () {
    if (this.niming) {
      this.loading = false
    } else {
      this.find_order_index()
    }
  },
  methods: {
    find_order_index (e) {
      axios.post('/find_order_index/', {})
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.loading = false
          this.user_info = res.data.data.user_info
        })
    },
    find_order_by_order (e) {
      if (this.niming) {
        this.show.zc = true
        return
      }
      if (this.order_id === '') {
        Utils.alert({ content: { content: '订单号不可为空' } })
        return
      }
      const data = { order_id: this.order_id }
      axios.post('/find_order_by_order/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.other_user_info = res.data.data.info
          this.has_order = true
        })
    },
    move_user_by_order (e) {
      if (this.niming) {
        this.show.zc = true
        return
      }
      if (this._disabled2) {
        return
      }
      this._disabled2 = true
      if (this.order_id === '') {
        Utils.alert({ content: { content: '订单号不可为空' } })
        return
      }
      const data = { order_id: this.order_id }
      axios.post('/move_user_by_order/', data)
        .then(res => {
          this._disabled2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          Utils.show_snackbar(this.other_user_info.vip_info.is_vip ? '会员和收藏名字已迁移到本账号，原账号雪藏' : '收藏名字已迁移到本账号，原账号雪藏')
          Utils.go_to_index()
        })
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    on_close () {
      this.show = { contact: false }
    }
  }
}
</script>
<style scoped>
  .med p{
    padding:0.1rem 0.5rem;
  }
  .input_div{
  padding: 0.2rem 0.5rem;
  }
  .input_div input,.input_div button{
    vertical-align: top;
  }
  .input_div input{

    display: inline-block;
    height: 0.9rem;
    line-height: 0.9rem;
    width: 6rem;
    border-radius: 0.10rem;
    background-color: #f8f8f8;
    text-align: left;
    border-left: 0.4rem solid #f8f8f8;
    border-right: none;
    border-top: none;
    border-bottom: none;
  }
  .input_div span{
    display: inline-block;
    width: 1.4rem;
    margin: 0rem 0rem 0rem 0.5rem;
    height: 0.8rem;
    line-height: 0.8rem;
    background-color: #212B36;
    color: #fefefe;
    text-align: center;
    border-radius: 0.1rem;
    font-size: 0.36rem;
  }

.other_user_div{
  margin: 0.2rem 0.5rem;
  text-align: center;
}

.other_user_div li{
  line-height: 1rem;
  height: 1rem;
  display: inline-block;
  border: 0.02rem solid #eee;
  font-size: 0.36rem;
  text-align: left;
  white-space: nowrap;
  text-overflow: scroll;
  overflow: scroll;
  color: #999;

}
.other_user_div ul{
  margin: 0;
  padding: 0;
}
.other_user_div .al2{
  width: 4rem;
}
.other_user_div .al1{
  width: 8rem;
}
</style>
